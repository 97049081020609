/**
 * Write-only the password as cookie
 */
 import React, { useState, useEffect } from "react";
import { setSessionPassword,isProtectedPage} from '@mkitio/gatsby-theme-password-protect/src/utils/utils.js';
import { Link, navigate, graphql, useStaticQuery } from "gatsby";
import { useColorMode } from "theme-ui";


const PasswordProtect = () => {
  const [password, setPassword] = useState('');
  const [isButtonHovered, buttonHover] = useState(false);
  const [isThemeHovered, themeHover] = useState(false);
  const [isSiteHovered, siteHover] = useState(false);
  const [colorMode, setColorMode] = useColorMode(); 
  const isDark = colorMode === `light`;
  
  const styles = {
    wrapperx: {
      height: '98vh',
      background: '#F5F5F5',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      copybackground: "rgba(97, 102, 220, 0.45)",
    boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
    backdropFilter: "blur(13.5px)",
    WebkitBackdropFilter: "blur(13.5px)",
    borderRadius: "10px",
    border: "1px solid rgba(255, 255, 255, 0.18)"
    },
    h1: {
      fontFamily: "Merriweather",
      // color: '#000',
      color: colorMode === "dark" ? "#fff" : "#000",
      textAlign:"center",
    },
    input: {
      width: '100%',
      height: '48px',
      borderRadius: '4px'
    },
    button: {
      width: '103%',
      height: '48px',
      background: '#6166DC',
      color: '#fff',
      border: '1px solid #6166DC',
      borderRadius: '4px',
      marginTop: '16px',
      textTransform: 'uppercase',
      fontWeight: '300',
      fontFamily: 'sans-serif'
    },
    buttonHover: {
      background: '#fff',
      color: '#000000'
    },
    link: {
      color: '#fff',
      fontFamily: 'sans-serif'
    },
    linkHover: {
      color: 'dodgerblue'
    },
    svg :{
      fill: colorMode === "dark" ? "#fff" : "#000",
    }
  };
  

  const onSubmit = event => {
    event.preventDefault();
    setSessionPassword(password);
    console.log();
    window.location.reload(); // eslint-disable-line
  };

  return (
      <>
    <div style={isProtectedPage?null:styles.wrapperx}>
    <Link to="/"   className="Logo__Mobile">
      <svg height="512px" id="Layer_1" style={{ width: "2rem", height: "2rem",position: "absolute", top: "24px", right: "24px", fill: styles.svg.fill }} version="1.1" viewBox="0 0 512 512" width="512px"><path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"/></svg>  
      </Link>  
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}>

      
<svg style={{width: "40%", opacity: 0.6,  maxWidth: "16rem", fill: styles.svg.fill }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" enable-background="new 0 0 50 50"><path d="M34 23h-2v-4c0-3.9-3.1-7-7-7s-7 3.1-7 7v4h-2v-4c0-5 4-9 9-9s9 4 9 9v4z"/><path d="M33 40H17c-1.7 0-3-1.3-3-3V25c0-1.7 1.3-3 3-3h16c1.7 0 3 1.3 3 3v12c0 1.7-1.3 3-3 3zM17 24c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h16c.6 0 1-.4 1-1V25c0-.6-.4-1-1-1H17z"/><circle cx="25" cy="28" r="2"/><path d="M25.5 28h-1l-1 6h3z"/>
</svg>

      <h1 style={styles.h1}>This content is protected.</h1>
      <h4 style={{ color: styles.svg.fill, opacity:"0.6", fontFamily:"'SF Pro Display','-apple-system','BlinkMacSystemFont','San Francisco','Helvetica Neue','Helvetica','Ubuntu','Roboto','Noto','Segoe UI','Arial',sans-serif",marginTop: 0,marginBottom: "3rem",textAlign:"center"}}>To view, please enter password.</h4>
      </div>
      <form onSubmit={onSubmit} style={{ width: '320px', margin:"auto" }}>
        <input
          name="password"
          type="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
          style={styles.input}
        />

        <button
          type="submit"
          style={{
            ...styles.button,
            ...(isButtonHovered ? styles.buttonHover : null),
            marginBottom: "1rem",
          }}

        >
          Enter
        </button>
      <a class="spl-a"  style={{ color: '#6166DC', opacity:"0.8", fontFamily:"'SF Pro Display','-apple-system','BlinkMacSystemFont','San Francisco','Helvetica Neue','Helvetica','Ubuntu','Roboto','Noto','Segoe UI','Arial',sans-serif",marginTop: "1rem",marginBottom: "3rem",textAlign:"center", margin:"auto"}} href="mailto:dev2919@gmail.com?subject=Portfolio Password request">Request password</a>
      </form>
    </div>
    </>
  );
};



export default PasswordProtect;
